@use '~@angular/material' as mat;

@mixin cards-theme($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);

    .card-header {
        font-size: 20px;
        color: mat.get-color-from-palette($accent, default-contrast);
        margin-left: 5%;
        margin-top: 50px;
        margin-bottom: 12px;
    }
}
