html,
body {
    height: 100%;
}

body {
    margin: 0;
}

app-root {
    position: relative;
    height: 100%;
}

#loader-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#loader {
    width: 100px;
    height: 100px;
    border: 16px solid #dadada;
    border-top: 16px solid #0d2192;
    border-radius: 50%;
    animation: loading 1s ease-in-out infinite;
}

@keyframes loading {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
